<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Overtime/Late ({{ot_late_param.total}})</h5>					
			</CCol>
			<CCol lg="6">
						<CButton 
						
								v-if="config.getPermission('ot_late').create"
								size="sm" style="margin-right: 10px" 
								color="success"  @click="addModal()" 
								class="float-lg-right"><i class="fa fa-plus"></i>Add Personnel
						</CButton> 
					</CCol>
		</CRow>
		<CRow class="mt-2">
			
					
	<CCol lg="12">
		<div class="form-inline">
			<Datepicker 
				@selected="changeStartDate"
				v-model="date_start"
				input-class="form-control bg-white mr-2" 
				placeholder="From"
				:minimumView="'month'" 
				:maximumView="'month'"
				:format="dateFormatter"
			>
			</Datepicker> 
		<Datepicker 
			@selected="changeEndDate"
			v-model="date_end"
				input-class="form-control bg-white" 
				placeholder="To"  
				:minimumView="'month'" 
				:maximumView="'month'"
				:format="dateFormatter"
			>
		</Datepicker> 
		<CButton 
			class="ml-2"
			v-if="config.getPermission('ot_late').download"
			size="sm" style="margin-right: 80px" 
			color="btn btn-outline-primary" @click="download" 
			
			>
			<i class="fa fa-download"></i> Download
		</CButton>	
	</div>	
</CCol>

			
</CRow>

		
		<hr>

		<CRow>			
	    
		<CCol lg="12" >  
			<div class="table-responsive-sm table-responsive-md">
	      		<CDataTable
				  	:tableFilter='{ placeholder: "Name, Type" }'
					pagination
					:itemsPerPage="10"
					itemsperPageSelect
					:loading="isLoading"
			      	:items="ot_late_Data"
			      	:fields="fields"
					hover
					outlined	
					itemsPerPageSelect
					
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
				</template>
			    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('ot_late').view" @click="viewModal(item)"><i class="fa fa-eye" >View</i></CDropdownItem>     
				            </CDropdown>
				        </td>
			      	</template> 
			    </CDataTable> 
			    <!-- <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(personnel_Data.length / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />  -->
			</div>

			
	      	</CCol>
	    </CRow> 
		</CCard>
<!-- VIEW -->
		<CModal :title="title" :show.sync="formModal" @close="closeModal()" color="info" size="md">
			
           	<form @submit.prevent="submit"> 
		
			
	
				<CRow>
					<CCol lg="12">
						<div class="form-group">
							<label style="font-size:12px">Crew (Personnel Name)  
							<span v-if="!is_disabled" class="text-danger">* </span></label>
							<v-select 
								label="name"
								:options="personnelList.data"
								:reduce="item => item.id"
								v-model="ot_late_param.personnel_id"
								placeholder="Select.." 
							>
								
							</v-select>
						</div> 
					</CCol>  
					
				</CRow>
				
			
					
	            <div slot="footer" class="w-100 mt-3">
					<!-- to avoid enabling button via inspect element -->
					<template v-if="!is_disabled">
	            	<CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                   Next
	                </CButton>
					<!-- <CButton size="sm" v-else type="button" style="border-radius: .2rem; color: white;" disabled color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton> -->
					</template>
	                <CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Close
	                </CButton>
	                
	            </div>
          	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>

		
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
// import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';

export default {
	mounted(){  
	   this.getData();  
	   this.getPersonnel();
	},
	filters: {
		formatDate(val) {
			if(val) 
			return moment.unix(val).format('MMMM DD, YYYY')
			return '';
		},
		uppercase(val) {
			if(val)
				return val.toUpperCase();
			return ''
		}
	},
	watch: {
		
	},
	computed: {
		
		is_disabled() {
			return !this.ot_late_param.personnel_id;
		}
	},
	data(){
		return{
			isLoading: false,
			config,
			dataParams: {
				
			},
			
			formModal: false,
			dataList: [],  
			fields: [
				
				{
					key: 'employee_name', 
					label: 'Name'
				}, 
				{
					key: 'personnel_type', 
					label: 'Personnel Type'
				},
				{
					key: 'balance_ot', 
					label: 'Balance OT'
				},
				{
					key: 'balance_late', 
					label: 'Balance Late'
				},							
				{
					key: 'action', 
					label: 'Action'
				}
			],
			currentPage: 1,  
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			
			filter: {
				adjustment_id :"",
				booking_no :"",
				plate_no :"",
				destination :"",
				personnel_id :"",
				delivery_date_from:"",
	            delivery_date_to:"",
				status : ""
	           
			},
			
			
			personnelList: {
				data: []
			},
			
			ot_late_Data: [
	
				

			],
			
			showViewModal : false,
			
			ot_late_param: {
				personnel_id: null,
				total:0
			},
			
			mode : {
				view: false,
				new: true,
				edit: false
			}
		}
	},
	name: 'Tables',
	components: {Datepicker, vSelect},
	methods: {
		dateFormatter(date) {
			return moment(date).format('MMMM YYYY');
		},
		download() {
			if (this.date_start == null || this.date_end == null) {
				Swal.fire({
				title: "Warning",
				text: "Please fill out From and To fields.",
				icon: "warning",
				});
			} 
			else if(moment(this.date_start).isAfter(moment(this.date_end))) {
				Swal.fire({
					title: "Warning",
					text: "Invalid date range.",
					icon: "warning",
				});
			}else {
        	this.$showLoading(true)
			axios
			.get(`${config.api_path}/otlate/download`, {
				params: {
				start_date: moment(this.date_start).format("YYYY-MM-DD"), // '2021-09-06',
				end_date: moment(this.date_end).format("YYYY-MM-DD"), // '2022-10-02'              
				},
			})
			.then((response) => {
				this.$showLoading(false)
				window.open(
				config.main_path.replace("/index.php", "") +
					"/" +
					response.data.file
				);

            setTimeout(() => {
              axios.get(`${config.api_path}/remove-file`, {
                params: {
                  filename: response.data.file,
                },
              });
			}, 2000);
          }).catch(err => {
				this.$showLoading(false)
			})
      }
    },
		
		
	submit() {
		console.log(this.ot_late_param.personnel_id)		
		window.open(`#/main/view_overtime_late/${this.ot_late_param.personnel_id}`, '_blank');
		
	},
	

	addModal(){
		this.setMode('new')
		this.formModal = !this.formModal;
		this.title = "Add Personnel";
		
	},
	
	setMode(type) {
		this.mode = {
			view: false,
			edit : false,
			new : false
		}
		this.mode[type] = true;
	},
	viewModal(item) {
		window.open(`#/main/view_overtime_late/${item.personnel_id}`, '_blank');
	},
	

	closeModal() {
		console.log('oyet')
	},
		reloadData(){
			this.getData();  
		},
		// updatePage(data){
		// 	this.currentPage = data;
		// 	this.getData();
		// },   

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(){  
			this.isLoading = true;
	    	axios.get(config.api_path+'/otlate/list',{
				params : {
					// page : this.currentPage,
					
				}
			}
			)
	    	.then(response => {
				this.isLoading=false
					this.ot_late_Data = response.data.data.map(item => {
					item.balance_late = item.balance_late ? this.$options.filters.number_format(item.balance_late, 'currency', 2) : '';
					item.balance_ot = item.balance_ot ? this.$options.filters.number_format(item.balance_ot, 'currency', 2) : '';
          			
					return item;
				 });

					this.ot_late_param.total = response.data.total;}
	    	)
			.catch(err => {
				console.error(err)
				
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;
			
			})
	    },

	    
	  
  	getPersonnel(){
		this.isLoading = true;
	    	axios.get(config.api_path+'/reference/personnel-list')
	    	.then(response => {
	    		this.personnelList = response.data;				
	    	})

	    },
		 }


}
</script>
